/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.about-container {
  padding: 2rem;
}

.about-container blockquote {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem auto 2rem auto;
  color: #000;
  text-align: center;
  max-width: 600px;
}

/* Title Section */
.title-section {
  text-align: center;
  background: #eef5e8;
  padding: 1.5rem 0;
}

.title-section h1 {
  font-size: 2rem;
  color: #567d46;
  font-weight: bold;
}

/* Flexbox for Carousel and Text */
.about-content2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem; /* Space between carousel and text */
  max-width: 1200px;
  margin: 0 auto;
}

.carousel2 {
  flex: 2; /* Occupies two-thirds of the space */
  max-width: 500px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-text2 {
  flex: 1; /* Occupies one-third of the space */
  text-align: left;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
}

/* Mid Section */
.about-contentmid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.about-textmid {
  text-align: center;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
}

/* Second Section with Carousel on the Right */
.about-content1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse; /* Reverses the order of text and carousel */
  gap: 2rem; /* Space between carousel and text */
  max-width: 1200px;
  margin: 0 auto;
}

.carousel1 {
  flex: 2; /* Occupies two-thirds of the space */
  max-width: 500px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-text1 {
  flex: 1; /* Occupies one-third of the space */
  text-align: left;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
}
/* Ensure images do not load gradually */
.carousel2 img,
.carousel1 img {
  transition: none; /* Disable gradual transitions */
  opacity: 1; /* Ensure images are fully visible immediately */
  will-change: auto; /* Reset any optimized behavior for gradual rendering */
}


/* Responsive Design */

/* For tablets (up to 1024px) */
@media (max-width: 1024px) {
  /* Adjust layout for tablets */
  .about-content2,
  .about-content1 {
      flex-direction: column; /* Stack carousel and text vertically */
      padding: 0 1rem; /* Add padding for better spacing */
  }

  .carousel2,
  .carousel1 {
      max-width: 90%; /* Allow carousel to take 90% of the available width */
      height: auto; /* Let the height adjust dynamically */
  }

  .about-text2,
  .about-text1 {
      text-align: center;
      font-size: 1rem; /* Increase text size for better readability */
      margin-top: 1rem;
  }

  .title-section h1 {
      font-size: 1.8rem; /* Adjust title size */
  }
}

@media (max-width: 768px) {
  /* For mobile tablets (up to 768px) */
  .about-content2,
  .about-content1 {
      flex-direction: column; /* Stack carousel and text vertically */
      padding: 0 1rem; /* Add padding to prevent text from touching screen edges */
  }

  .carousel2,
  .carousel1 {
      max-width: 100%; /* Full width for the carousel */
      height: 250px; /* Adjust height for mobile */
      margin-bottom: 1rem; /* Add space below the carousel */
  }

  .about-text2,
  .about-text1 {
      font-size: 0.95rem; /* Adjust font size slightly smaller */
      text-align: center;
  }

  .title-section h1 {
      font-size: 1.75rem; /* Adjust the font size of the title */
  }

  .about-textmid {
      font-size: 0.95rem; /* Adjust text size */
  }

  /* Adjust blockquote for mobile tablets */
  .about-container blockquote {
      font-size: 1.3rem;
      max-width: 100%; /* Allow blockquote to take full width */
  }
}

@media (max-width: 480px) {
  /* Adjust layout for mobile phones */
  .about-content2,
  .about-content1 {
      flex-direction: column; /* Stack carousel and text vertically */
      padding: 0 1rem; /* Add padding for mobile */
  }

  .carousel2,
  .carousel1 {
      max-width: 100%; /* Full width for carousel */
      height: 200px; /* Reduce carousel height for smaller screens */
      margin-bottom: 1rem;
  }

  .about-text2,
  .about-text1 {
      font-size: 0.9rem; /* Further reduce font size */
      text-align: center;
      line-height: 1.5;
  }

  .title-section h1 {
      font-size: 1.5rem; /* Reduce title size further for smaller screens */
  }

  /* Adjust blockquote for smaller screens */
  .about-container blockquote {
      font-size: 1.2rem;
      max-width: 100%; /* Allow blockquote to use full width */
  }

  /* Adjust about-textmid for mobile screens */
  .about-textmid {
      font-size: 0.9rem; /* Smaller font size */
      line-height: 1.4;
      padding: 1rem; /* Add padding for mobile readability */
  }
}

