/* Ensure the entire container matches the viewport dimensions */
.explorer-rishi-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh !important; /* Full viewport height */
    width: 100vw !important; /* Full viewport width */
    margin: 0 !important; /* Remove any margin */
    padding: 0 !important; /* Remove padding to avoid scrollbars */
    overflow: hidden;
    position: relative;
}

/* Center the video flexibly and scale it perfectly */
.video-flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Full height of parent container */
    width: 100%; /* Full width of parent container */
}

/* Ensure the video fills the container perfectly */
.video-container {
    position: relative;
    width: 100%; /* Full container width */
    height: 100%; /* Full container height */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Style the video to fit the screen perfectly */
.rishi-video {
    position: absolute; /* Stretch to container edges */
    top: 0;
    left: 0;
    width: 81.5%; /* Fill the container's width */
    height: 88%; /* Fill the container's height */
    object-fit: cover !important; /* Prevent distortion */
    z-index: 1; /* Ensure it appears properly in the layout */
}

/* Read More button centered on the video */
.read-more-btn {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 42%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center accurately based on dimensions */
    background-color: #88B88B; /* Match Get Started button's background */
    color: #FFF;
    border: none;
    padding: 0.7em 1.5em;
    border-radius: 5px;
    font-size: 1.2em;
    width: auto; /* Adapt to content size */
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Add shadow for a polished look */
    z-index: 2; /* Ensure it's on top of the video */
    opacity: 0;
    animation: fadeIn 1s forwards; /* Smooth fade-in effect */
}

.read-more-btn:hover {
    background-color: #6fa46e; /* Darker green on hover */
}

/* Fade-in animation for the "Read More" button */
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* Responsive adjustments for smaller devices */
@media (max-width: 1024px) {
    .rishi-video {
        width: 90%;
        height: auto; /* Maintain aspect ratio */
    }

    .read-more-btn {
        font-size: 1em; /* Adjust button size */
        padding: 0.5em 1.2em;
    }
}

@media (max-width: 768px) {
    .rishi-video {
        width: 100%; /* Fit to screen width */
        height: auto; /* Maintain aspect ratio */
    }

    .read-more-btn {
        font-size: 0.9em; /* Slightly smaller font size */
        padding: 0.5em 1em;
        top: 30%; /* Adjust position for smaller screens */
        left: 50%;
    }
}

@media (max-width: 480px) {
    .rishi-video {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
    }

    .read-more-btn {
        font-size: 0.8em; /* Smaller font size */
        padding: 0.4em 0.8em;
        top: 30%; /* Further adjust position */
    }
}
