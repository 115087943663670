/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

/* Contact Section */
.contact-section {
  background-color: white;
  padding: 3em 1em;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.contact-section h1 {
  font-size: 2.5em;
  color: #4D774E;
  background-color: #F0F8E7;
  padding: 1em 2em;
}

.contact-subtitle {
  font-family: 'Roboto Slab', serif;
  font-size: 1.8rem;
  color: #3a5939;
  margin-bottom: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.contact-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
  padding: 0 10px;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 1.5em;
  margin: 2em 0;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between;
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F0F8E7;
  padding: 1em;
  width: 220px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
  box-sizing: border-box;
}

.contact-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 0.8em;
}

.contact-item span {
  font-size: 1em;
  color: #333;
  text-align: center;
}

/* Hover Effect */
.contact-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #eaf7dd;
}

/* Enquiry Form */
.enquiry-form {
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.enquiry-form h2 {
  font-size: 1.5em;
  color: #4D774E;
  margin-bottom: 1em;
}

.enquiry-form input,
.enquiry-form textarea {
  padding: 0.5em;
  margin: 0.5em 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-btn {
  background-color: #88B88B;
  color: #FFF;
  border: none;
  font-size: 1.2em;
  padding: 0.5em;
  border-radius: 4px;
  cursor: pointer;
}


.enquiry-form select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 14px;
  color: #333333bb;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.enquiry-form select:hover {
  border-color: #4D774E;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(56, 239, 81, 0.5);
}

.enquiry-form select:focus {
  border-color: #4D774E;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(56, 239, 81, 0.5);
}


/* Responsive Design */
@media (max-width: 1024px) {
  .contact-info {
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center; /* Center items */
  }

  .contact-section h1 {
    font-size: 2rem;
  }

  .contact-item {
    width: 45%; /* Adjust contact item width */
  }

  .enquiry-form {
    width: 90%; /* Ensure form doesn't overflow */
  }
}

@media (max-width: 768px) {
  .contact-item {
    width: 180px;
  }

  .contact-section h1 {
    font-size: 1.8rem;
  }

  .enquiry-form {
    width: 80%; /* Make the form smaller on tablets */
  }

  .enquiry-form input,
  .enquiry-form textarea {
    font-size: 0.9em;
  }

  .submit-btn {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .contact-item {
    width: 150px;
  }

  .contact-section h1 {
    font-size: 1.5rem;
  }

  .contact-info {
    justify-content: center; /* Ensure items are centered */
  }

  .enquiry-form {
    width: 100%; /* Ensure form fits within screen width */
    padding: 1em;
  }

  .enquiry-form input,
  .enquiry-form textarea {
    font-size: 0.85em;
    padding: 0.7em;
  }

  .submit-btn {
    font-size: 1.1em;
    padding: 0.7em;
  }

  .contact-text {
    font-size: 0.9em; /* Smaller font for mobile devices */
  }

  .contact-subtitle {
    font-size: 1.5rem; /* Slightly smaller subtitle for mobile */
  }
}
