/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
}

/* Gallery Section */
.gallery-sectionrishikesh {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  width: 1350px;
  margin-left: 70px;
  gap: 0.5rem;
  flex-wrap: wrap; /* Added to allow wrapping on smaller screens */
}

.main-imagerishikesh {
  position: relative;
  width: 600px;
  height: 400px;
}

.main-imagerishikesh img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.main-textrishikesh {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  margin: auto;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.7);
}

/* Thumbnail Gallery beside the main image */
.thumbnail-galleryrishikesh {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 400px;
}

.thumbnail-galleryrishikesh img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* Retreat Package Section */
.retreat-packagerishikesh {
  display: flex;
  align-items: center;
  padding: 2rem;
  border-top: 1px solid #e0e0e0;
  margin: 2rem auto;
  max-width: 1000px;
}

.retreat-imagerishikesh {
  width: 350px;
  height: 350px;
  margin-right: 2rem;
}

.retreat-imagerishikesh img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.retreat-detailsrishikesh {
  flex: 1;
}

.retreat-detailsrishikesh h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.retreat-detailsrishikesh p {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 1rem;
}

.retreat-detailsrishikesh span {
  font-weight: bold;
}

.package-buttonrishikesh {
  background-color: #d4ecd2;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.retreat-detailsrishikesh ul {
  list-style: none;
  padding-left: 0;
  line-height: 1.5;
  font-size: 0.9rem;
  color: #555;
}

.price-sectionrishikesh {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.price-sectionrishikesh p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-right: 1rem;
}

.price-sectionrishikesh .book-button {
  background-color: #c4e2c1;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s;
  margin-left: auto;
  margin-right: 20px;
}

.price-sectionrishikesh .book-button:hover {
  background-color: #a9d1a1;
}

/* Retreat Details and Schedule Sections */
.retreat-details-sectionrishikesh,
.schedule-sectionrishikesh {
  padding: 2rem;
  max-width: 1000px;
  margin: 2rem auto;
  text-align: left;
  border-top: 1px solid #e0e0e0;
}

.retreat-details-sectionrishikesh h3,
.schedule-sectionrishikesh h3 {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 1rem;
  color: #688c59;
}

.retreat-details-sectionrishikesh p,
.schedule-sectionrishikesh p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

.retreat-details-sectionrishikesh button,
.schedule-sectionrishikesh button {
  background-color: transparent;
  color: #567d46;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: bold;
  text-decoration: underline;
}

.retreat-details-sectionrishikesh button:hover,
.schedule-sectionrishikesh button:hover {
  color: #a9d1a1;
}

/* Locations Section */
.locations-sectionrishikesh {
  padding: 2rem;
  max-width: 1000px;
  margin: 2rem auto;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.locations-sectionrishikesh h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.locations-imagerishikesh img {
  width: 100%;
  height: auto;
  max-width: 900px;
  border-radius: 10px;
}

.locationrishi {
  font-size: 0.9rem;
  color: #000000;
  margin: 0.5rem 0;
  font-weight: bold;
}

/* Media Queries for Responsiveness */

/* Tablet and below */
@media (max-width: 1024px) {
  .gallery-sectionrishikesh {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }

  .main-imagerishikesh {
    width: 100%;
    height: auto;
  }

  .thumbnail-galleryrishikesh {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
  }

  .thumbnail-galleryrishikesh img {
    width: 100%;
    height: auto;
  }

  .retreat-packagerishikesh {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }

  .retreat-imagerishikesh {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .price-sectionrishikesh {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .gallery-sectionrishikesh {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .main-imagerishikesh {
    width: 100%;
    height: auto;
  }

  .thumbnail-galleryrishikesh {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .retreat-packagerishikesh {
    flex-direction: column;
    align-items: center;
  }

  .retreat-imagerishikesh {
    width: 100%;
    height: auto;
  }

  .retreat-detailsrishikesh {
    text-align: center;
  }

  .locations-sectionrishikesh {
    padding: 1rem;
  }
}

/* Very Small Mobile */
@media (max-width: 480px) {
  .gallery-sectionrishikesh {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }

  .main-imagerishikesh {
    width: 100%;
    height: auto;
  }

  .thumbnail-galleryrishikesh {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    width: 100%;
  }

  .thumbnail-galleryrishikesh img {
    width: 100%;
    height: auto;
  }

  .retreat-packagerishikesh {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .retreat-imagerishikesh {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  .retreat-detailsrishikesh {
    text-align: center;
  }
}
