/* Reset styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Title Section */
.title-section {
  text-align: center;
  background: #eef5e8; /* Light green background */
  padding: 2rem 0;
}

.title-section h1 {
  font-size: 2.5rem;
  color: #567d46;
}

/* Description and Carousel Section */
.description-carousel-section {
  display: flex;
  align-items: flex-start;
  padding: 0; /* Remove extra padding to align with green background */
  flex-direction: column; /* Adjust layout to ensure content stacks */
}

.description {
  width: 100%;
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  padding: 1rem; /* Add some padding for better text spacing */
}

/* Carousel Section */
.carousel {
  position: relative;
  width: 100%; /* Make carousel span full width */
  max-width: 1200px; /* Optional: Limit max width for better visuals */
  margin: 0 auto; /* Center the carousel horizontally */
  height: 500px; /* Adjust height if needed */
  overflow: hidden;
  border-radius: 10px;
}

.carousel-image {
  width: 100%; /* Match carousel container's width */
  height: 100%; /* Cover the carousel height */
  object-fit: cover;
  border-radius: 10px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bolder;
  border-radius: 50%;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8); /* Increased shadow for a stronger effect */
}

.carousel-button.prev {
  right: 1150px; /* Adjust as needed */
}

.carousel-button.next {
  left: 1150px; /* Adjust as needed */
}

/* Testimonials Section */
.testimonials {
  text-align: center;
  padding: 2rem;
}

.testimonials h2 {
  font-size: 2rem;
  color: #567d46;
}

.testimonial-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.testimonial-card {
  background-color: #fff;
  width: 30%;
  height: auto;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: left;
  margin: 1rem;
}

.stars {
  color: #ffd700;
  margin-bottom: 0.5rem;
}

.reviewer-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.reviewer-info img {
  width: 90px;
  height: 60px;
 
  border-radius: 50%;
  margin-right: 0.5rem;
}

/* Gallery Section */
.gallery {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
  margin-top: 10px;
  flex-wrap: wrap; /* Ensure gallery items wrap on smaller screens */
}

.gallery-video {
  width: 350px;
  height: 350px;
  border-radius: 10px;
  object-fit: fill;
  padding-top: 0.1rem;
  margin-top: 10px;
  transition: transform 0.3s;
}

.gallery-video:hover {
  transform: scale(1.05);
  margin-top: 10px;
}


/* Media Queries for Responsive Design */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  /* Description Section */
  .description {
      font-size: 0.9rem; /* Slightly smaller font size for better readability */
      padding: 1rem; /* Add padding for spacing */
  }

  /* Testimonials Section */
  .testimonial-cards {
      flex-direction: column; /* Stack testimonial cards vertically */
      align-items: center; /* Center the cards */
  }

  .testimonial-card {
      width: 80%; /* Wider testimonial cards for mobile */
      margin-bottom: 1rem; /* Add space between stacked cards */
  }

  /* Title Section */
  .title-section h1 {
      font-size: 2rem; /* Adjust title font size for tablets */
  }

  /* Carousel Section */
  .carousel {
      height: 400px; /* Reduce carousel height for tablet-sized screens */
  }

  /* Carousel Button */
  .carousel-button {
      font-size: 1.3rem; /* Slightly smaller buttons for tablets */
  }

  /* Gallery Section */
  .gallery {
      flex-direction: column; /* Stack gallery videos vertically */
      align-items: center; /* Center the gallery videos */
  }

  .gallery-video {
      width: 80%; /* Make videos take more space on tablet screens */
      height: 250px;
      margin-top: 10px; /* Adjust height for better fit */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  /* Description Section */
  .description {
      font-size: 0.85rem; /* Even smaller font for mobile */
      padding: 1rem; /* Adequate padding for mobile devices */
  }

  /* Testimonials Section */
  .testimonial-card {
      width: 100%; /* Full-width testimonial cards on mobile */
      margin-bottom: 1rem; /* Space between each card */
  }

  /* Title Section */
  .title-section h1 {
      font-size: 1.8rem; /* Further reduce title size for mobile */
  }

  /* Carousel Section */
  .carousel {
      height: 250px; /* Reduce height of carousel on smaller phones */
  }

  /* Carousel Button */
  .carousel-button {
      font-size: 1.2rem; /* Smaller buttons on smaller phones */
      padding: 0.3rem; /* Adjust padding for buttons */
  }

  /* Gallery Section */
  .gallery {
      flex-direction: column; /* Stack gallery videos vertically on small phones */
      align-items: center; /* Center the gallery videos */
  }

  .gallery-video {
      width: 90%; /* Make videos take more space */
      height: 250px; /* Reduce height of videos on small phones */
      margin-bottom: 1rem; /* Space between videos */
  }
}

/* For smaller phones (e.g., iPhone SE, up to 375px) */
@media (max-width: 375px) {
  /* Description Section */
  .description {
      font-size: 0.8rem; /* Reduce font size for smaller screens */
      padding: 1rem; /* Adequate padding for mobile devices */
  }

  /* Testimonials Section */
  .testimonial-card {
      width: 100%; /* Full-width testimonial cards on smaller phones */
      margin-bottom: 1rem; /* Space between each card */
  }

  /* Title Section */
  .title-section h1 {
      font-size: 1.6rem; /* Further reduce title size for smaller phones */
  }

  /* Carousel Section */
  .carousel {
      height: 220px; /* Reduce height further on very small screens */
  }

  /* Carousel Button */
  .carousel-button {
      font-size: 1.1rem; /* Adjust for smaller buttons on very small screens */
      padding: 0.25rem; /* Adjust padding for buttons */
  }

  /* Gallery Section */
  .gallery {
      flex-direction: column; /* Stack gallery videos vertically */
      align-items: center; /* Center the gallery videos */
  }

  .gallery-video {
      width: 95%; /* Increase width for small screens */
      height: 200px; /* Further reduce video height */
      margin-bottom: 1rem; /* Space between videos */
  }
}
