/* Reset and basic styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f4f4f4;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-links button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.nav-links .active {
  color: #4CAF50;
}

.book-now {
  background-color: #d6e8c8;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.book-now:hover {
  background-color: #c5dbb3;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  color: #333;
  padding: 5rem 0;
  min-height: 60vh; /* Adjusts the height of the hero section */
}

.hero::before {
  content: "";
  background-image: url('./assets/3.jpg'); /* Replace this with the actual background image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.6; /* Adjust transparency level here */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.hero h1 {
  font-size: 3.5rem; /* Increase font size for visibility */
  font-family: 'Arial', sans-serif; /* Use a cleaner, more visible font */
  font-weight: bold;
  color: #28682b;
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0); /* Darker background for text readability */
  padding: 1rem 2rem;
  border-radius: 8px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Adds a shadow for extra contrast */
  transform: translateY(-35%); /* Move text up slightly */
}

/* Gallery Section */
.gallery {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.gallery-item img {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
}

.see-more-container {
  margin-top: 2rem; /* Add spacing above the button */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  width: 100%; /* Ensure it spans full width of the container */
}

.see-more {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  background-color: #b6e4b7;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.see-more:hover {
  background-color: #a5d3a6;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: 100%; /* Make sure image width adjusts to the screen */
  max-width: 100%; /* Ensure the image fits within the screen */
  max-height: 80vh; /* Allow image height to adjust proportionally */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  object-fit: contain; /* Ensures the entire image fits within the container */
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  transform: translateY(-50%);
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

/* Media Queries */

/* For tablets and small screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .navbar {
      padding: 1rem;
  }

  .hero h1 {
      font-size: 2.5rem;
      transform: translateY(-25%);
  }

  .gallery-grid {
      grid-template-columns: repeat(3, 1fr); /* Reduce grid columns */
  }

  .gallery-item img {
      width: 200px;
      height: 200px;
  }

  .modal-image {
      max-height: 60vh; /* Reduce the height for tablets */
  }

  /* Adjust book-now button */
  .book-now {
    padding: 0.5rem 1.5rem;
    font-size: 0.9rem;
  }
}

/* For mobile screens (max-width: 768px) */
@media (max-width: 768px) {
  .navbar {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
  }

  .nav-links {
      flex-direction: column;
      gap: 0.5rem;
  }

  .hero {
      padding: 3rem 1rem;
      min-height: 50vh;
  }

  .hero h1 {
      font-size: 2rem;
      padding: 1rem;
      transform: translateY(0); /* Reset position */
  }

  .gallery-grid {
      grid-template-columns: repeat(2, 1fr); /* Reduce to 2 columns */
  }

  .gallery-item img {
      width: 150px;
      height: 150px;
  }

  .see-more {
      font-size: 0.9rem;
      padding: 0.5rem 1.5rem;
  }

  .modal-image {
      max-height: 50vh; /* Further reduce the image size for mobile */
  }

  /* Adjust the book-now button */
  .book-now {
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
  }
}

/* For very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .navbar {
      padding: 1rem 0.5rem;
  }

  .hero {
      padding: 2rem 0.5rem;
      min-height: 40vh;
  }

  .hero h1 {
      font-size: 1.5rem;
      padding: 0.5rem;
  }

  .gallery-grid {
      grid-template-columns: 1fr; /* Single column layout */
  }

  .gallery-item img {
      width: 100%;
      height: auto;
  }

  .see-more {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
  }

  .modal-image {
      max-height: 40vh; /* Further reduce the image size for very small screens */
  }

  /* Adjust the book-now button */
  .book-now {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem;
  }
}
