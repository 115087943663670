/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}

/* Hero Section */
.herohome {
  position: relative;
  background-size: cover;
  background-position: center;
  color: #000000;
  font-weight: bold;
  padding: 100px 20px;
  text-align: center;
  transition: background-image 1s ease-in-out;
}

.herohome::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(152, 146, 146, 0.655);
  z-index: 1;
}

.hero-contenthome {
  position: relative;
  z-index: 2;
}

.herohome h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.herohome p {
  margin-bottom: 30px;
  font-size: 1.2rem;
}

.cta-btnhome {
  padding: 10px 20px;
  background-color: #88b88b;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Media Queries for Hero Section */
@media (max-width: 768px) {
  .herohome {
    padding: 80px 15px;
  }

  .herohome h1 {
    font-size: 2.5rem;
  }

  .herohome p {
    font-size: 1rem;
  }

  .cta-btnhome {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .herohome h1 {
    font-size: 2rem;
  }

  .herohome p {
    font-size: 0.9rem;
  }

  .cta-btnhome {
    padding: 8px 12px;
    font-size: 0.8rem;
  }
}

/* Past Retreats Section */
.past-retreatshome {
  margin-top: 40px;
  text-align: center;
}

.past-retreatshome h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.retreats-gridhome {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 20px;
}

.retreat-cardhome {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.retreat-cardhome:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.retreat-cardhome img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-contenthome {
  padding: 15px;
  text-align: center;
}

.retreat-cardhome h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.retreat-cardhome p {
  font-size: 0.9rem;
  color: #666;
  margin: 10px 0;
}

.read-more-btnhome {
  background-color: #88b88b;
  color: white;
  border: none;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.retreat-cardhome:hover .read-more-btnhome {
  background-color: #99b22d;
  transform: scale(1.1);
}

.read-more-btnhome:hover {
  background-color: #88a629;
}

/* Media Queries for Past Retreats Section */
@media (max-width: 768px) {
  .retreats-gridhome {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .retreat-cardhome img {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .retreats-gridhome {
    grid-template-columns: 1fr;
  }

  .retreat-cardhome h3 {
    font-size: 1.2rem;
  }

  .retreat-cardhome p {
    font-size: 0.8rem;
  }

  .read-more-btnhome {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

/* General Styling */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9a9;
}

.herohome {
  color: white;
  text-align: center;
  padding: 60px 20px;
}

.hero-contenthome h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-contenthome p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-btnhome {
  background-color: #b2d235;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-btnhome:hover {
  background-color: #99b22d;
}

/* Media Queries for General Adjustments */
@media (max-width: 1024px) {
  .hero-contenthome h1 {
    font-size: 2.5rem;
  }

  .hero-contenthome p {
    font-size: 1.1rem;
  }

  .cta-btnhome {
    font-size: 1rem;
    padding: 12px 25px;
  }
}

@media (max-width: 768px) {
  /* Hero Section */
  .hero-contenthome h1 {
    font-size: 2.3rem;
  }

  .hero-contenthome p {
    font-size: 1rem;
  }

  .cta-btnhome {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  /* Past Retreats Section */
  .past-retreatshome h2 {
    font-size: 1.8rem;
  }

  /* General Adjustments for Layout */
  .retreat-cardhome img {
    height: 160px;
  }
}

@media (max-width: 480px) {
  .hero-contenthome h1 {
    font-size: 1.8rem;
  }

  .hero-contenthome p {
    font-size: 0.9rem;
  }

  .cta-btnhome {
    padding: 8px 15px;
    font-size: 0.8rem;
  }

  .retreat-cardhome h3 {
    font-size: 1.2rem;
  }

  .retreat-cardhome p {
    font-size: 0.8rem;
  }

  .read-more-btnhome {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

