/* Reset and Basics */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

/* Navbar */
header.navbar {
  display: flex;
  justify-content: space-between; /* Space between logo, nav, and button */
  align-items: center; /* Vertically align items */
  padding: 1rem 2rem; /* Add padding */
  background-color: white; /* White background */
  border-bottom: 1px solid #ddd; /* Optional subtle bottom border */
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar-logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2f5f31; /* Green color for logo */
  margin-right: 7rem; /* Space between logo and nav links */
}

nav {
  display: flex;
  gap: 2rem; /* Adjust gap for consistent spacing */
  flex-grow: 1; /* Allow links to expand to fill available space */
  justify-content: flex-start; /* Align links to the left */
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-btn {
  text-decoration: none;
  color: #2f5f31;
  font-size: 1rem;
  width: auto;
  font-weight: bold;
  transition: color 0.3s ease;
  white-space: nowrap; /* Ensure "Online Courses" and "Contact Us" stay on one line */
}

.nav-btn:hover {
  color: #6fa46e; /* Slightly lighter green on hover */
}

.book-now-btn {
  background-color: #88b88b;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.9em 1.5em;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-left: 3rem; /* Space between nav links and button */
  white-space: nowrap;
}

.book-now-btn:hover {
  background-color: #6fa46e;
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.navbar-logo-container {
  display: flex;
  align-items: center; /* Align logo and text vertically */
  gap: 0.2rem; /* Reduce the spacing between the logo and the text */
}

/* Logo image styling */
.navbar-logo-image {
  height: 60px; /* Adjust logo size */
  width: auto; /* Maintain aspect ratio */
}

/* Logo text styling */
.navbar-logo-text {
  font-size: 1rem;
  font-weight: bold;
  color: #2f5f31; /* Green color for logo text */
  white-space: nowrap;

 
}

/* Hero Section with animated background */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/background.png');
  background-size: 200%; 
  background-position: center; 
  background-repeat: no-repeat;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.5); /* Blue overlay with 50% opacity */
  z-index: -1; /* Ensures the overlay is behind other content */
}

.video-container {
  position: relative;
  width: 150%;
  height: 100%;
  overflow: hidden;
}

.background-video {
  width: 60%;
  height: 100%;
  max-height: 120vh;
  object-fit: cover;
  object-position: 50% 25%; /* Aligns the top part of the video to be visible */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  opacity: 0;
  transition: opacity 1s ease;
}

.overlay.fade-in {
  opacity: 1;
}

.overlay.fade-out {
  opacity: 0;
}

.welcome-text {
  font-size: 3em; /* Large size for the main heading */
  color: #fff; /* White text */
  margin-bottom: 0.3em; /* Smaller spacing between welcome text and tagline */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Subtle shadow for better visibility */
  font-family: 'Arial', sans-serif; /* Default bold font for welcome text */
}

.tagline {
  font-size: 1.5em; /* Slightly smaller than the welcome text */
  color: #ffffff; /* White color */
  font-style: italic; /* Italic style for tagline */
  font-family: 'Georgia', 'Times New Roman', serif; /* Elegant serif font for tagline */
  margin-top: -1.3em; /* Slight negative margin to pull closer to welcome text */
  margin-bottom: 1em; /* Reduced margin for tighter spacing below tagline */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Matching shadow for consistency */
}

/* Add spacing control for the entire overlay content */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  opacity: 0;
  transition: opacity 1s ease;
  gap: 0.5em; /* Control spacing between text elements */
}

/* Get Started Button */
.get-started-btn {
  background-color: #88B88B;
  color: #FFF;
  border: none;
  padding: 0.7em 1.5em;
  border-radius: 5px;
  font-size: 1.2em;
  width: 20%;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.get-started-btn:hover {
  background-color: #6fa46e;
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Container for the dropdown */
.book-now-container {
  position: relative; /* Ensure dropdown is positioned relative to button */
}

/* Dropdown menu styling */
.dropdown {
  position: absolute;
  top: 100%; /* Place below the button */
  left: 0;
  background-color: white; /* White background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  border-radius: 5px;
  overflow: hidden; /* Prevent content overflow */
  z-index: 1000; /* Ensure it appears above other elements */
  width: 200px; /* Set dropdown width */
}

/* Dropdown options */
.dropdown-option {
  display: block;
  padding: 0.8em 1em;
  text-align: left;
  background-color: #fff;
  border: none;
  font-size: 1rem;
  color: #2f5f31; /* Match the green color of other buttons */
  cursor: pointer;
  width: 100%;
  text-decoration: none;
}

.dropdown-option:hover {
  background-color: #88b88b; /* Highlight color on hover */
  color: white; /* Text color on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  /* Tablet */
  .hero-section {
    height: 90vh;
  }

  .welcome-text {
    font-size: 3 rem;
  }

  .get-started-btn {
    font-size: 1rem;
    padding: 1.6 rem 1.4 rem;
  }

  nav {
    gap: 0.5rem;
    justify-content: center;
  }

  .nav-btn {
    font-size: 0.9rem;
  }

  .navbar-logo {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2f5f31; /* Green color for logo */
    margin-right: 0rem; /* Space between logo and nav links */
  }
}

@media (max-width: 768px) {
  /* Mobile */
  header.navbar {
    flex-direction: column;
    padding: 1rem;
  }

  nav {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .book-now-btn {
    margin-top: 1rem;
  }

  .hero-section {
    height: 80vh;
  }

  .welcome-text {
    font-size: 2.5 rem;
  }

  .get-started-btn {
    font-size: 0.9rem;
    padding: 0.9rem 1.3rem;
  }

  .navbar-logo {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2f5f31; /* Green color for logo */
    margin-right: 0rem; /* Space between logo and nav links */
  }
}

@media (max-width: 480px) {
  /* Small Mobile */
  .navbar-logo {
    font-size: 1.2rem;
  }

  nav {
    gap: 0.3rem;
  }

  .nav-btn {
    font-size: 0.8rem;
  }

  .welcome-text {
    font-size: 2rem;
  }

  .get-started-btn {
    font-size: 0.8rem;
    padding: 0.6rem 1.2rem;
  }

  .navbar-logo {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2f5f31; /* Green color for logo */
    margin-right: 0rem; /* Space between logo and nav links */
  }
}

/* Footer */
.footer {
  background-color: #e0f7e0; /* Light green background */
  color: black; /* Black text */
  text-align: center;
  padding: 20px 10px;
  font-size: 14px;
}

.footer a {
  text-decoration: none;
  color: black;
}

.footer-link {
  color: black;
  font-weight: bold;
}

.footer-link:hover {
  color: darkgreen;
}

.contact-info {
  margin-bottom: 10px;
}

.contact-info a {
  display: block;
  margin: 5px 0;
  font-size: 16px;
  color: black;
}

.social-icon {
  margin-right: 8px;
  vertical-align: middle;
  color: black;
}

.social-icon:hover {
  color: darkgreen; /* Change to dark green on hover */
}

.contact-info a:hover {
  color: darkgreen;
}

footer p {
  margin-top: 20px;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer-column {
    margin-bottom: 20px;
    text-align: center;
  }
  .footer-sections {
    gap: 10px;
  }
}
