/* Profile Section */
.profile-section {
  display: flex;
  justify-content: center;
  padding: 40px;
  background-color: #d7e3d9; /* Background color */
}

/* Profile Card */
.profile-card {
  display: flex;
  width: 1200px;
  height: 680px;
  background-color: #5b7e61; /* Darker background color for the card */
  padding: 30px;
  border-radius: 10px;
  align-items: center;
  color: #ffffff; /* White text color */
  position: relative; /* Enable absolute positioning for the image */
  box-sizing: border-box;
}

/* Profile Image */
.profile-image {
  width: 510px;
  height: 640px; /* Increased height for a pop-out effect */
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Shadow for pop-out effect */
  position: relative;
  z-index: 1; /* Place image above the card */
}

/* Profile Content */
.profile-content {
  text-align: center;
  width: 800px;
  z-index: 0;
  max-width: 100%;
}

.profile-content h2 {
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 15px;
}

.profile-content h3 {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  padding-top: 15px;
}

.profile-content p {
  font-size: 16px;
  line-height: 1.5;
  color: #e0e0e0; /* Lighter color for paragraph */
  margin-bottom: 20px;
}

/* Social Links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 10px 0;
}

.social-links a {
  color: #ffffff; /* Change text color to white */
  text-decoration: none;
  font-weight: bold;
}

.social-links a:hover {
  color: #093d10; /* Optional hover effect for links */
}

.social-icon {
  margin-right: 8px;
  font-size: 30px;
  color: #ffffff; /* Change icon color to white */
}  

/* Responsive Design */
@media (max-width: 1200px) {
  .profile-card {
      width: 100%;
      padding: 20px;
      height: auto;
  }

  .profile-image {
      width: 40%; /* Adjust width of image */
      height: auto;
  }

  .profile-content {
      width: 55%; /* Adjust content section width */
  }
}

@media (max-width: 1024px) {
  .profile-section {
      padding: 30px;
  }

  .profile-card {
      width: 100%;
      height: auto;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
  }

  .profile-image {
      width: 60%;
      height: auto;
      margin-right: 0;
      margin-bottom: 20px;
  }

  .profile-content {
      width: 100%;
      text-align: center;
  }

  .profile-content h2 {
      font-size: 24px;
  }

  .profile-content h3 {
      font-size: 18px;
  }

  .profile-content p {
      font-size: 16px;
  }

  .social-links {
      font-size: 18px;
      gap: 20px;
  }

  .social-icon {
      font-size: 26px;
  }
}

@media (max-width: 768px) {
  .profile-section {
      padding: 20px;
  }

  .profile-card {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px;
      height: auto;
  }

  .profile-image {
      width: 80%;
      height: auto;
      margin-right: 0;
      margin-bottom: 20px;
  }

  .profile-content {
      width: 100%;
  }

  .profile-content h2 {
      font-size: 24px;
  }

  .profile-content h3 {
      font-size: 20px;
  }

  .profile-content p {
      font-size: 16px;
  }

  .social-links {
      flex-direction: column;
      font-size: 16px;
  }

  .social-icon {
      font-size: 22px;
  }
}

@media (max-width: 480px) {
  .profile-section {
      padding: 20px;
  }

  .profile-card {
      padding: 15px;
      height: auto;
  }

  .profile-content h2 {
      font-size: 22px;
  }

  .profile-content h3 {
      font-size: 16px;
  }

  .profile-content p {
      font-size: 14px;
  }

  .social-icon {
      font-size: 20px;
  }

  /* Ensure that the image width is 100% for smaller screens */
  .profile-image {
      width: 90%; /* Take up most of the screen width */
      margin-bottom: 20px;
  }

  .profile-content {
      width: 100%;
  }
}
