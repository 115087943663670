/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: auto;
}

body {
  font-family: Arial, sans-serif;
}

/* Title Section */
.title-sectiononline {
  text-align: center;
  background: #eef5e8;
  padding: 2rem 0;
}

.title-sectiononline h1 {
  font-size: 2.5rem;
  color: #567d46;
  font-weight: bold;
}

/* Courses Section */
.courses-sectiononline {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-cardonline {
  display: flex;
  background: #fff;
  width: 100%;
  max-width: 900px; /* Limit card width for large screens */
  height: 400px;
  margin: 1rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.course-cardonline:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.course-imageonline img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.course-detailsonline {
  padding: 1rem;
  flex-grow: 1;
}

.course-detailsonline h2 {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}

.locationonline {
  font-size: 1rem;
  color: #000000;
  margin: 0.5rem 0;
  font-weight: bold;
}

.package-tagonline {
  background-color: #c4e2c1;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #567d46;
  font-size: 0.8rem;
}

.course-detailsonline ul {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #555;
}

.course-detailsonline ul li {
  margin: 0.3rem 0;
}

.course-footeronline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.priceonline {
  font-size: 1.2rem;
  color: #567d46;
  font-weight: bold;
}

.book-now-buttononline {
  background-color: #c4e2c1;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.book-now-buttononline:hover {
  background-color: #a3d7a4;
}

/* Ensure Modal Overlays don't hide content */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  text-align: center;
  background-color: #ffffff;
  width: 90%;
  max-width: 1000px; /* Increased max-width for larger modal */
  padding: 40px; /* Extra padding for more space */
  border-radius: 12px; /* Rounded corners for a modern feel */
  font-family: 'Georgia', serif;
  color: #333333;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Deeper shadow for depth */
  display: flex;
  flex-direction: column;
  gap: 30px; /* Increased gap between elements */
  animation: slideIn 0.3s ease-out; /* Smooth entrance animation */
  max-height: 90vh; /* Restrict the height of modal */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 0; /* Ensure no margin above the content */
  margin-bottom: 0; /* Ensure no margin below the content */
  justify-content: flex-start; /* Align content at the top */
}

/* Modal Close Button */
.modal-close-button {
  position: absolute;
  top: 20px; /* Distance from the top */
  right: 25px; /* Distance from the right */
  font-size: 1.5rem; /* Size of the button */
  color: #4b8a2b; /* Green color for the button */
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Change color on hover */
.modal-close-button:hover {
  color: #a3d7a4; /* Lighter green shade on hover */
  transform: scale(1.1); /* Slight zoom effect */
}

/* Slide-in animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modal Title */
.modal-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #4b8a2b; /* Vibrant and lively green */
  margin-bottom: 15px;
  border-bottom: 3px solid #4b8a2b; /* Bold underline for emphasis */
  padding-bottom: 8px;
  text-align: center; /* Center-aligned for a more polished look */
}

/* Price and Date */
.modal-price {
  font-size: 1.3rem;
  font-weight: 500;
  color: #555555;
  margin-bottom: 20px;
  border-bottom: 1px dashed #bbbbbb; /* Subtle dashed underline */
  padding-bottom: 8px;
  text-align: center;
}

/* Section Title */
.section-title {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #4b8a2b;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.08rem; /* Slightly more spacing for modern feel */
  text-align: center;
}

/* Schedule List */
.schedule-list {
  border: 1px solid #dddddd;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  font-size: 1rem;
  line-height: 1.7;
  background: linear-gradient(180deg, #ffffff, #f8f8f8); /* Subtle gradient for texture */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.schedule-list-item {
  text-align: left;
  margin-bottom: 14px;
  font-weight: 500;
  color: #444444;
  display: flex;
  align-items: center;
  gap: 10px;
}

.schedule-list-item::before {
  color: #4b8a2b;
  font-size: 1.3rem;
}

/* Workshop Details */
.workshop-details {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-top: 12px;
  color: #333333;
}

/* Booking Section */
.booking-section {
  margin-top: auto; /* Push it to the bottom */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eeeeee;
  padding-top: 20px;
}

.booking-info {
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
}

/* Booking Button */
.booking-button {
  background: linear-gradient(90deg, #4b8a2b, #66b346); /* Smooth gradient */
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 14px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Elevated shadow for impact */
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.booking-button:hover {
  background: linear-gradient(90deg, #3b7023, #57923b);
  transform: translateY(-2px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.3);
}

/* Scrollable Modal Section */
.scrollable-modal {
  max-height: 80vh; /* Make modal height responsive */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 1rem; /* Prevent content from overlapping scrollbar */
}

/* Custom Scrollbar for Modal */
.scrollable-modal::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.scrollable-modal::-webkit-scrollbar-thumb {
  background: #4b8a2b; /* Scrollbar thumb color */
  border-radius: 5px;
}

.scrollable-modal::-webkit-scrollbar-thumb:hover {
  background: #3a6f24; /* Darker shade on hover */
}

.scrollable-modal::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track background */
}


/* Responsive Design */

/* For Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .modal-content {
    padding: 25px;
    gap: 20px;
  }

  .modal-title {
    font-size: 2rem;
  }

  .section-title {
    font-size: 1.6rem;
  }

  .schedule-list {
    padding: 15px;
    font-size: 1rem;
  }

  .booking-button {
    padding: 12px 25px;
    font-size: 1.1rem;
  }
}

/* For Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .modal-content {
    padding: 20px;
  }

  .modal-title {
    font-size: 1.8rem;
  }

  .section-title {
    font-size: 1.4rem;
  }

  .schedule-list {
    font-size: 0.95rem;
  }

  .booking-button {
    width: 100%;
    padding: 14px;
    font-size: 1rem;
  }
}

/* Responsive Design */

/* For Tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .course-cardonline {
    flex-direction: column;
    height: auto;
  }

  .course-imageonline img {
    width: 100%;
    height: auto;
  }

  .title-sectiononline h1 {
    font-size: 2rem;
  }

  .course-detailsonline h2 {
    font-size: 1.1rem;
  }

  .priceonline {
    font-size: 1rem;
  }

  .book-now-buttononline {
    font-size: 0.8rem;
  }
}

/* For Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .title-sectiononline h1 {
    font-size: 1.5rem;
  }

  .course-detailsonline h2 {
    font-size: 0.9rem;
  }

  .locationonline {
    font-size: 0.9rem;
  }

  .package-tagonline {
    font-size: 0.7rem;
  }

  .course-footeronline {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .priceonline {
    font-size: 1rem;
  }

  .book-now-buttononline {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem;
    width: 100%;
  }

  .courses-sectiononline {
    padding: 1rem;
  }

  .course-cardonline {
    margin: 1rem 0;
  }

  .course-imageonline img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .course-detailsonline ul {
    font-size: 0.7rem;
  }
}
